html{
  height: 100%;
}
body{
  height: 100%;
  background-color: #000;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.clear{
  clear: both;
}
.sc-bdvvtL {
  display: block !important;
 
}
.fvuGvo {
  color: #000; 
}
#pin_form {
  display: block;
  width: 100%;
  text-align: center;
}
.error {
  color: #fff;
}
.img_top {
  width: 400px;
  margin:75px 0px;
}
#pin {
  text-align: center;
}
#pin h2 {
  color: #00E7AA;
  font-size: 30px;
  font-weight: 400;
}
.chrome {
  color: #ACACAC;
  margin-top: 50px;
}
p.error {
  color: #FF4536;
  font-size: 18px;
}
#form {
  display: none;
}
iframe {
  position: fixed;
  height: 100%;
  width: 100%;
}
#logo {
  position: absolute;
  width: 145px;
  top:0;
  margin: 10px;
  z-index: 99;
}
#logo img {
  width: 100%;
}
#swipeMenu ul li a {
  color: #fff;
  display: inline-block;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  text-decoration: none;
  margin-left: 10px;
}
#swipeMenu ul {
 margin: 20px 0 20px 0;
 padding: 0;
}
#swipeMenu li {
  list-style: none;
}
#swipeMenu li img {
 display:inline-block;
 width: 15px;
}
#swipeMenu {
  color:#fff;
  background: #000;
  width: 300px;
  height: 100%;
  position: fixed;
  right:-370px;
  top: 58px;
  z-index: 99;
  transition: all 1s ease-out;
  padding: 20px;
}
#swipeMenu p {
  color: #fff;
}
#bg_menu {
  position: fixed;
  right: -100%;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 1s ease-out;
  z-index: 99;
}
#bg_menu.leave{
  right: -100%;
  transition: all 1s ease-out;
}
#bg_menu.enter{
  right: 0;
  transition: all 1s ease-out;
}
#swipeMenu.leave{
  right: -370px;
  transition: all 1s ease-out;
}
#swipeMenu.enter{
  right: 0;
  transition: all 1s ease-out;
}
#menu {
  position: absolute;
  right: 0;
  margin: 5px;
  z-index: 98;
}
#mobile {
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  text-align: center;
  display: block;
}
#mobile img {
  width: 360px;
  margin-top: 100px;
}
#mobile h2 {
  color: #00E7AA;
  font-size: 30px;
  font-weight: 400;
  padding: 20px;
}
#mobile p {
  color: #999;
}
#menu_bg {
  background: #292626;
  height: 58px;
  position: fixed;
  border-bottom: solid 1px #000;
  z-index: 999;
  width: 100%;
}
@media screen and (min-width:1000px) {
  #mobile {
    display: none;
  }
}